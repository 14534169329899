import jateImg from "../Assets/Images/jate.jpg";
import bnbImg from "../Assets/Images/dtpreview1.jpg"


const projects = [
    {
        title: "Beans & Brews: Revisited",
        description:
          "Renovated application for Beans and Brews Coffee House.",
        img: "https://github.com/j-art-fox/Project-3-Beans-and-Brews/raw/main/client/src/images/dtpreview1.jpg",
        url: "https://beans-and-brew.herokuapp.com/",
        key: "0",
        repurl: "https://github.com/j-art-fox/Project-3-Beans-and-Brews",
      },
    {
      title: "JEST",
      description:
        "Browser based graphical user interface for helping developers create source code.",
      img: jateImg,
      url: "https://the-jate-text-editor.herokuapp.com",
      key: "1",
      repurl: "https://github.com/j-art-fox/Text-Editor-Demo",
    },
    {
      title: "Password Generator",
      description:
        "Customizeable password generator that can include multiple character types.",
      img: "https://github.com/j-art-fox/Password-Generator-/raw/main/assets/images/preview1.png",
      url: "https://j-art-fox.github.io/Password-Generator-/",
      key: "2",
      repurl: "https://github.com/j-art-fox/Password-Generator-",
    },
    {
      title: "E-Commerce Back-End",
      description:
        "Mock-up of a fully operational API created with SQL, express and Sequelize.",
      img: "https://github.com/j-art-fox/E-Commerce-Back-End/raw/main/Assets/img/Screen%20Shot%202022-09-15%20at%2010.37.24%20AM.png",
      url: "https://github.com/j-art-fox/E-Commerce-Back-End",
      key: "3",
      repurl: "https://github.com/j-art-fox/E-Commerce-Back-End",
    },
    {
      title: "Git Surveys",
      description:
        "A survey builder for creating and surveying people easily online.",
      img: "https://github.com/benbushman98/gitsurveys/raw/main/public/images/screenshot.png",
      url: "https://www.gitsurveys.com/",
      key: "4",
      repurl: "https://github.com/benbushman98/gitsurveys",
    },
    {
      title: "Fantasy Football Picks",
      description:
        "Get news, stats, and ratings to help you make fantasy football picks.",
      img: "https://github.com/kolbykimball/project-1/raw/main/assets/images/screencapture-127-0-0-1-5502-resultsPage-html-2022-08-01-19_06_01.png",
      url: "https://kolbykimball.github.io/project-1/",
      key: "5",
      repurl: "https://github.com/kolbykimball/project-1",
    },
    {
      title: "The Note Taker",
      description:
        "Web Application that allows users to easily take and save notes on their browser's local storage.",
      img: "https://github.com/j-art-fox/Task-Manager/blob/main/public/assets/IMG/prev2.png?raw=true",
      url: "https://still-harbor-79286.herokuapp.com/notes",
      key: "6",
      repurl: "https://github.com/j-art-fox/Task-Manager",
    }
];

  export default projects;